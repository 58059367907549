@font-face {
    font-family: 'Mont';
    src: url('/fonts/Mont/Mont-Regular.eot');
    src: local('Mont Regular'), local('Mont-Regular'),
    url('/fonts/Mont/Mont-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Mont/Mont-Regular.woff2') format('woff2'),
    url('/fonts/Mont/Mont-Regular.woff') format('woff'),
    url('/fonts/Mont/Mont-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url('/fonts/Mont/Mont-SemiBold.eot');
    src: local('Mont SemiBold'), local('Mont-SemiBold'),
    url('/fonts/Mont/Mont-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Mont/Mont-SemiBold.woff2') format('woff2'),
    url('/fonts/Mont/Mont-SemiBold.woff') format('woff'),
    url('/fonts/Mont/Mont-SemiBold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}